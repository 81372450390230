import {Route, Routes} from "react-router-dom";
import Info from "./pages/Info";
import Details from "./pages/Details";
import License from "./pages/License";
import Report from "./pages/Report";

function App() {
    return (
        <Routes>
            <Route path={'/'} element={<Info />} />
            <Route path={'/details'} element={<Details />} />
            <Route path={'/business'} element={<License />} />
            <Route path={'/report'} element={<Report />} />
            {/*<Route path={'/help'} element={<Help />} />*/}
        </Routes>
    );
}

export default App;
