import React, {Component} from "react";
import BackImg from '../images/img.png'

export default class BackgroundImage extends Component {
    render() {
        return (
            <div style={{flex: 1, width: '100vw', height: '25vh',}}>
                <img
                    style={{
                        width: "100vw",
                        height: "25vh",
                        flex: 1,
                        position: 'absolute',
                        resizeMode: 'cover',
                        zIndex: 0,
                    }}
                    src={BackImg}
                    alt={"特设码"}
                />
                <div
                    style={{
                        backgroundImage: "linear-gradient(to top, rgb(76, 166, 255),rgba(76, 166, 207, 0.75),rgba(255, 255, 255, 0.00))",
                        width: "100vw",
                        height: "25vh",
                        position: "absolute",
                        zIndex: 0,
                    }}
                />
                {this.props.children}
            </div>
        )
    }
}
