import {http, to} from "../utils/http";

const licenseApi = {
    // 公司执照
    async findCompanyLicense(params) {
        let url = `/license/company`;
        const [err, res] = await to(http.get(url, params));
        if (err) {
            //请求失败
            return Object.assign(err, {
                code: -1,
                msg: err
            }, true);
        }
        //请求成功
        return res;
    },

    // 检验报告
    async findEqReport(eqId) {
        let url = `/license/eq/${eqId}`;
        const [err, res] = await to(http.get(url));
        if (err) {
            //请求失败
            return Object.assign(err, {
                code: -1,
                msg: err
            }, true);
        }
        //请求成功
        return res;
    },
}

export default licenseApi
