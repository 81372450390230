import {Box, Button, Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {Eq_Info_Key} from "../consts";
import {useNavigate} from "react-router-dom";
import licenseApi from "../api/license_api";
import DownAppDialog from "../components/DownAppDialog";

function License () {

    const [eqInfo, setEqInfo] = useState({})
    const [licenseImage, setLicenseImage] = useState("")
    const [downAppVisible, setDownAppVisible] = useState(false)

    let navigate = useNavigate()

    useEffect(() => {
        // 设备信息
        const eq = window.localStorage.getItem(Eq_Info_Key)
        if (eq && JSON.parse(eq)) {
            setEqInfo(JSON.parse(eq))
        } else {
            navigate(-1)
        }

    }, [navigate])

    useEffect(() => {
        if (!eqInfo || !eqInfo.id) {
            return
        }

        let params = {
            companyId: eqInfo.companyId,
            companyUserId: eqInfo.lastCompanyUser
        }
        licenseApi.findCompanyLicense(params).then(r => {
            if (r.data !== undefined) {
                let file = "https://api.upload.ants23.com:30125"+r.data.license.Path+"?download=0"
                if (r.data.license.Path) {
                    setLicenseImage(file)
                }
            }
        })

    }, [eqInfo])

    return <>
        <Box sx={{ flexGrow: 1 }}>
            <div style={{ width: "100vw", height: "5vh", background: "rgb(76, 166, 255)" }} />
            <h2>企业证照</h2>
            {licenseImage ? <img
                src={licenseImage}
                alt={"营业执照"}
                style={{
                    margin: "0 10vw",
                    width: "80vw",
                    height: "50vh"
                }}
                onClick={() => setDownAppVisible(true)}
            /> : <>
                <Grid container alignItems={"center"}>
                    <Grid item xs={12} style={{textAlign:"center", marginTop: "10vh"}}>
                        <Button>该企业暂未上传营业执照</Button>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center", marginTop: "10vh"}}>
                        <Button variant={"outlined"} onClick={() => setDownAppVisible(true)}>点击上传营业执照</Button>
                    </Grid>
                </Grid>
            </>}
        </Box>
        <DownAppDialog
            visible={downAppVisible}
            onClose={() => setDownAppVisible(false)}
        />
    </>
}

export default License
