import logo from '../logo.png';
import SendIcon from '@mui/icons-material/Send';
import {
    Avatar,
    Button,
    Divider,
    Grid,
    LinearProgress,
    List, ListItem, ListItemText,
} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useEffect, useState} from "react";
import httpApi from "../api/qrcode_api";
import eqApi from "../api/eq_api";
import BackgroundImage from "../components/BackgroundImage";
import {useNavigate} from "react-router-dom";
import {Eq_Info_Key, Qrcode_Id_Key} from "../consts";

export default function Info() {

    let navigate = useNavigate()

    const [qrcodeId, setQrcodeId] = useState("")
    const [eqInfo, setEqInfo] = useState({})
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState(`唯一ID: ${qrcodeId}`)

    useEffect(() => {
        const id = window.localStorage.getItem(Qrcode_Id_Key)
        const queryId = new URLSearchParams(window.location.search).get("qrcodeId")

        if (queryId) {
            window.localStorage.setItem(Qrcode_Id_Key, queryId)
            setQrcodeId(queryId)
            return
        }

        if (id) {
            setQrcodeId(id)
        }

        if (id === queryId) {
            setQrcodeId(id)
        } else if (id !== queryId && queryId) {
            setQrcodeId(queryId)
        }
    }, [])

    useEffect(() => {
        if (eqInfo) {
            window.localStorage.setItem(Eq_Info_Key, JSON.stringify(eqInfo))
        }
    }, [eqInfo])

    useEffect(() => {
        setLoading(true)
        queryQrcode(qrcodeId).then(resp => {
            if (resp.code === 0) {
                queryEq(resp.data.useId).then(eqResp => {
                    if (eqResp.code === 0 && eqResp.data) {
                        setEqInfo(eqResp.data)
                        closeLoading()
                        return
                    }

                    setTitle("该特设码未绑定信息，请前往APP绑定")
                })
                return
            }
            setTitle("下载APP查看更多")
        })
    }, [qrcodeId])

    const closeLoading = () => {
        setTimeout(() => {
            setLoading(false)
        }, 200)
    }

    const queryEq = async (eqId) => {
        return await eqApi.findEq(eqId)
    }
    const queryQrcode = async (qrcodeId) => {
        return await httpApi.getQrcode(qrcodeId)
    }

    const Status = (props) => {
        let statusInt = parseInt(props.status)
        if (statusInt === 1) {
            return <span style={{ color: '#17B70D' }}>在用</span>
        } else if (statusInt === 2) {
            return <span style={{ color: '#979797' }}>未使用</span>
        } else if (statusInt === 3) {
            return <span style={{ color: '#FF3140' }}>报废</span>
        } else {
            return <span style={{ color: '#FF3140' }}>删除</span>
        }
    }

    const HavePolice = (props) => {
        let statusInt = parseInt(props.have_police)
        if (statusInt === 1) {
            return <span style={{ color: 'rgb(76, 166, 255)' }}>已安装</span>
        } else {
            return <span style={{ color: '#979797' }}>未安装</span>
        }
    }
    return (
        <>
            {loading ?
                <>
                    <LinearProgress />
                    <Grid container style={{marginTop: "30vh", marginBottom: "10vh"}}>
                        <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <h4>{qrcodeId}</h4>
                        </Grid>
                        {qrcodeId ?
                            <>
                                <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <h4>{title}</h4>
                                </Grid>
                            </> : <></>}
                        <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Avatar
                                src={logo}
                                alt="logo"
                                sx={{ width: 150, height: 150 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Button
                                variant={"outlined"}
                                endIcon={<SendIcon />}
                                onClick={() => {
                                    window.open("https://teshema.ants23.com/help.pdf")
                                }}
                            >
                                查看使用手册
                            </Button>
                        </Grid>
                        <Grid item xs={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Button
                                variant={"contained"}
                                endIcon={<SendIcon />}
                                href={"https://cc.ants23.com/%E7%89%B9%E8%AE%BE%E7%A0%81.apk"}
                            >
                                下载特设码APP
                            </Button>
                        </Grid>
                    </Grid>
                </>: <>
                    <BackgroundImage />
                    <Divider />
                    <List
                        style={{
                            background: "rgb(76, 166, 255)", color: "#fff",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap"
                        }}
                    >
                        <ListItem
                            onClick={() => {
                                navigate({
                                    pathname: "/details",
                                    search: `?qrcodeId=${qrcodeId}&eqId=${eqInfo.id}`,
                                }, { replace: true })
                            }}
                            secondaryAction={
                                <ArrowForwardIosIcon />
                            }
                        >
                            <ListItemText>{eqInfo.company}</ListItemText>
                        </ListItem>
                    </List>
                    <List
                        style={{
                            paddingBottom: "5vh"
                        }}
                    >
                        <ListItem>
                            <ListItemText>{"名称：" + eqInfo.eq_name}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText>{"设备种类：" + eqInfo.eq_type}</ListItemText>
                        </ListItem>
                        <Divider />
                        {eqInfo.eq_type === "压力管道" ?
                            <>
                                <ListItem>
                                    <ListItemText>{"使用登记证号：" + eqInfo.serial_code}</ListItemText>
                                </ListItem>
                            </> :
                            <>
                                <ListItem>
                                    <ListItemText>{"设备代码：" + eqInfo.register_code}</ListItemText>
                                </ListItem>
                            </>
                        }
                        <Divider />
                        <ListItem>
                            <ListItemText>状态：<Status status={eqInfo.status}/></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText>声光报警装置：<HavePolice have_police={eqInfo.have_police}/></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText>{"下次检验日期：" + eqInfo.next_check_time}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText>{"使用单位：" + eqInfo.company}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText>{"安装地址：" + eqInfo.install_address}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText>{"监管责任所队：" + eqInfo.supervisory_team}</ListItemText>
                        </ListItem>
                        <Divider />
                    </List>

                    <Button
                        variant={"contained"}
                        endIcon={<SendIcon />}
                        href={"https://cc.ants23.com/%E7%89%B9%E8%AE%BE%E7%A0%81.apk"}
                        style={{position: "fixed", bottom: "10vh", margin: "0 auto", left: 0, right: 0, width: "70vw"}}
                    >
                        点击下载特设码APP
                    </Button>
                </>
            }
        </>
    );
}
