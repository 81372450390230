import {http, to} from "../utils/http";

const eqApi = {
    // 设备查询
    async findEq(eqId) {
        let url = `/eq/${eqId}`;
        const [err, res] = await to(http.get(url));
        if (err) {
            //请求失败
            return Object.assign(err, {
                code: -1,
                msg: err
            }, true);
        }
        //请求成功
        return res;
    },

    //企业信息统计
    async countEqCheckSearch(params) {
        let url = `/company/count`;
        const [err, res] = await to(http.get(url, params));
        if (err) {
            //请求失败
            return Object.assign(err, {
                code: -1,
                msg: err
            }, true);
        }
        //请求成功
        return res;
    },
}

export default eqApi;
