let header = {
    'Content-Type': "multipart/form-data",
    "accept": "application/json"

    // "accessToken": token  //用户登陆后返回的token，某些涉及用户数据的接口需要在header中加上token
};

// promise的封装操作
export const to = promise => {
    return promise
        .then(data => {
            return [null, data];
        })
        .catch(err => [err]);
}

export const http = {
    get(url, params) {
        if (params) {
            let paramsArray = [];
            //拼接参数
            Object.keys(params).forEach(key => paramsArray.push(key + '=' + params[key]))
            if (url.search(/\?/) === -1) {
                url += '?' + paramsArray.join('&')
            } else {
                url += '&' + paramsArray.join('&')
            }
        }

        return new Promise(function (resolve, reject) {
            fetch(url)
                .then((response) => {
                    return response.json()
                })
                .then((responseData) => {
                    resolve(responseData);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    post(url, params) {
        let formData = new FormData();
        if (typeof params !== "object") {
            return
        }
        if (Object.values(params).length > 0) {
            for (let [key, item] of Object.entries(params)) {
                formData.append(key, item)
            }
        }

        return new Promise(function (resolve, reject) {
            fetch( url, {
                method: 'POST',
                headers: header,
                body: formData
            }).then((response) => {
                return response.json()
            })
                .then((responseData) => {
                    //网络请求成功返回的数据
                    resolve(responseData);
                })
                .catch((err) => {
                    //网络请求失败返回的数据
                    reject(err);
                });
        });
    },
};
