import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";

function DownAppDialog (props) {
    return <Dialog
        open={props.visible}
        onClose={() => props.onClose()}
    >
        <DialogTitle>下载APP查看更多</DialogTitle>
        <DialogActions>
            <Button
                onClick={() => props.onClose()}
            >
                关闭
            </Button>
            <Button
                onClick={() => {
                    props.onClose()
                    window.open("https://cc.ants23.com/%E7%89%B9%E8%AE%BE%E7%A0%81.apk")
                }}
            >
                去下载
            </Button>
        </DialogActions>
    </Dialog>
}

export default DownAppDialog
