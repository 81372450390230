import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper
} from "@mui/material";
import baogaofabu from '../images/card/baogaofabu.png'
import drxx43 from '../images/card/drxx43.png'
import jubanpeixunban from '../images/card/jubanpeixunban.png'
import yingyezhizhao from '../images/card/yingyezhizhao.png'
import eqApi from "../api/eq_api";
import {Eq_Info_Key} from "../consts";
import DownAppDialog from "../components/DownAppDialog";

function Details () {
    const [searchParams] = useSearchParams()
    const [eqInfo, setEqInfo] = useState({})
    const [eqCount, setEqCount] = useState(0)
    const [workerCount, setWorkerCount] = useState(0)
    const [downAppVisible, setDownAppVisible] = useState(false)

    let navigate = useNavigate()

    useEffect(() => {
        const qrcodeId = searchParams.get("qrcodeId")
        const eqId = searchParams.get("eqId")
        if (!qrcodeId || !eqId) {
            navigate(-1)
        }

        // 设备信息
        const eq = window.localStorage.getItem(Eq_Info_Key)
        if (eq && JSON.parse(eq)) {
            setEqInfo(JSON.parse(eq))
        } else {
            navigate(-1)
        }
    }, [navigate, searchParams])

    useEffect(() => {
        if (eqInfo && eqInfo.id) {
            queryCountInfo()
        }
    }, [eqInfo])

    const queryCountInfo = () => {
        let params = {
            eqId: eqInfo.id,
            companyId: eqInfo.companyId,
            companyUserId: eqInfo.lastCompanyUser,
            eqType: eqInfo.eq_type,
        }
        eqApi.countEqCheckSearch(params).then(r => {
            if (r.data !== undefined) {
                setEqCount(r.data.eqCount)
                setWorkerCount(r.data.workerCount)
            }
        })
    }

    return <>
        <Box sx={{ flexGrow: 1, background: "rgb(76, 166, 255)", height: "100%"}}>
            <div style={{ width: "100vw", height: "5vh", background: "rgb(76, 166, 255)" }} />
            <Grid
                container
                alignItems={"center"}
                justifyContent={"space-around"}
                className={"box"}
            >
                <Grid xs={5} item className={"card"}>
                    <Paper elevation={3} onClick={() => navigate({ pathname: "/business" }, { replace: true })}>
                        <img src={yingyezhizhao}  alt={"营业执照"}/>
                        <div className={"desc"}>企业证照</div>
                    </Paper>
                </Grid>
                <Grid xs={5} item className={"card"}>
                    <Paper elevation={3} onClick={() => navigate({ pathname: "/report" }, { replace: true })}>
                        <img src={baogaofabu}  alt={"检验报告"}/>
                        <div className={"desc"}>检验报告</div>
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                alignItems={"center"}
                justifyContent={"space-around"}
                className={"box"}
            >
                <Grid xs={5} item className={"card"}>
                    <Paper elevation={3} onClick={() => setDownAppVisible(true)}>
                        <img src={drxx43}  alt={"作业人员"}/>
                        <div className={"desc"}>作业人员</div>
                    </Paper>
                </Grid>
                <Grid xs={5} item className={"card"}>
                    <Paper elevation={3} onClick={() => setDownAppVisible(true)}>
                        <img src={jubanpeixunban}  alt={"考试培训"}/>
                        <div className={"desc"}>考试培训</div>
                    </Paper>
                </Grid>
            </Grid>
            <Grid
                container
                alignItems={"center"}
                justifyContent={"space-around"}
                className={"box"}
                style={{paddingBottom: "5vh", background: "#fff"}}
            >
                <Grid xs={5} item className={"card"}>
                    <div className={"number"}>{eqCount}<span className={"numberDesc"}> 台</span></div>
                    <div className={"numberDesc"}>设备数量</div>
                </Grid>
                <Grid xs={5} item className={"card"}>
                    <div className={"number"}>{workerCount}<span className={"numberDesc"}> 人</span></div>
                    <div className={"numberDesc"}>作业人员数量</div>
                </Grid>
            </Grid>
            <List
                style={{
                    width: "100%",
                    color: "#fff",
                }}
                subheader={
                    <ListSubheader component="div" style={{textAlign: "center", fontSize: "20px", color: "#fff", background: "rgb(76, 166, 255)"}}>
                        企业填报
                    </ListSubheader>
                }
            >
                <Divider color={"#fff"} />
                <ListItem onClick={() => setDownAppVisible(true)}>
                    <ListItemText>{"叉车点检记录"}</ListItemText>
                </ListItem>
                <Divider color={"#fff"} />
                <ListItem onClick={() => setDownAppVisible(true)}>
                    <ListItemText>{"意见反馈"}</ListItemText>
                </ListItem>
                <Divider color={"#fff"} />
                <ListItem onClick={() => setDownAppVisible(true)}>
                    <ListItemText>{"修改负责人信息"}</ListItemText>
                </ListItem>
            </List>
        </Box>
        <DownAppDialog
            visible={downAppVisible}
            onClose={() => setDownAppVisible(false)}
        />
    </>
}

export default Details
